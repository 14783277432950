$background-color-wrapper: #f8f9fa;
$padding-bottom-wrapper: 30px;
$padding-top-responsive: 80px;

.wrapper {
  display: flex;

  .contentWrapper {
    background-color: $background-color-wrapper !important;
    width: 100%;
    overflow-x: hidden;
    padding-bottom: $padding-bottom-wrapper;

    @media screen and (max-width: 768px) {
      padding-top: $padding-top-responsive;
    }

    .content {
      flex: 1 0 auto;
    }
  }
}
