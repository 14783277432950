@use '../../assets/global' as global;

$custom-colors: global.$custom-colors;

@include global.text-colors($custom-colors);
@include global.background-colors($custom-colors);
@include global.outline-colors($custom-colors);

.button {
  max-height: 37.6px;
  overflow: hidden;
  white-space: nowrap;

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
    opacity: 0.65;
  }
}

.noHover {
  pointer-events: none;
  cursor: default !important;
}

.longText {
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;

  @media (max-width: 992px) and (min-width: 768px) {
    font-size: 13px;
  }

  @media (max-width: 768px) {
    font-size: 10px;
  }
}

.btn-xs {
  padding: 0.2rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.3 !important;
  border-radius: 0.2rem !important;
}
